import React, { createContext, useReducer } from "react";

// declare AuthContext as a Context object
export const AuthContext = createContext();

const initialState = {
  allLista: [],
  allCarros: [],
  allComercias: [],
  allClassicos: [],
  allPorEncomenda: [],
  allDisponivelBrevemente: [],

  allMotas: [],
  allMotasNovas: [],
  allBarcos: [],
  codPontoVenda: 0,
  allinfo: [],
  infoPrincipal: [],

  userToken: null,
};

// reducer to manage auth state
const reducer = (prevState, action) => {
  switch (action.type) {
    case "AddLista":
      return {
        ...prevState,
        allLista: action.list,
      };

    case "AddCarros":
      return {
        ...prevState,
        allCarros: action.list,
      };
    case "AddComercias":
      return {
        ...prevState,
        allComercias: action.list,
      };
    case "AddClassicos":
      return {
        ...prevState,
        allClassicos: action.list,
      };
    case "AddMotas":
      return {
        ...prevState,
        allMotas: action.list,
      };
      case "AddMotasNovas":
        return {
          ...prevState,
          allMotasNovas: action.list,
        };
    case "AddBarcos":
      return {
        ...prevState,
        allBarcos: action.list,
      };
    case "AddPontoVenda":
      return {
        ...prevState,
        codPontoVenda: action.value,
      };
    case "AddInfo":
      return {
        ...prevState,
        allinfo: action.list,
      };
    case "AddPrincipal":
      return {
        ...prevState,
        infoPrincipal: action.list,
      };
    case "AddEncomenda":
      return {
        ...prevState,
        allPorEncomenda: action.list,
      };
    case "AddBrevemente":
      return {
        ...prevState,
        allDisponivelBrevemente: action.list,
      };
    default:
      return initialState;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
