import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Tab, TabContent, Tabs } from "react-bootstrap";
import FormPesquisaAvancada from "./FormPesquisaAvancada";
import css from "../pesquisa.module.css";

import { ReactComponent as Seta } from "../../../../../assets/icons/pesquisa/seta_pesquisa.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery ";
import { listPesquisaAvancado } from "./hooks/listPesquisaAvancado";

export default function PesquisaAvancada({
  ArrayEncomenda,
  ArrayBrevemente,
  ArrayComerciais,
  ArrayClassicos,
  allListaCarros,
  setFilteredCarros,
  infoJson,
  allListaMotas,
  allListaMotasNovas,
  allListaBarcos,
  setAvancada,
  avancada,
  allLista,
}) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [key, setKey] = useState("");

  let { viatura } = useParams();
  console.log('viatura', viatura)



  useEffect(() => {
    if (viatura) {
      switch (viatura) {
        case "viaturas":
        case "automoveis":
          setKey("automoveis");
          break;
        case "comerciais":
          setKey("comerciais");
          break;
        case "classicos":
          setKey("classicos");
          break;
        case "motas-usadas":
          setKey("motas-usadas");
          break;
        case "motas-novas":
          setKey("motas-novas");
          break;
        case "disponivel-brevemente":
          setKey("disponivel-brevemente");
          break;
        case "por-encomenda":
          setKey("por-encomenda");
          break;
        case "barcos":
          setKey("barcos");
          break;
        default:
          setKey("automoveis");
          break;
      }
    } else {
      if (allListaCarros.length !== 0) {
        setKey("automoveis");
      } else {
        if (ArrayComerciais.length !== 0) {
          setKey("comerciais");
        } else {
          if (ArrayClassicos.length !== 0) {
            setKey("classicos");
          } else {
            if (ArrayEncomenda !== 0) {
              setKey("por-encomenda");
            } else {
              if (ArrayBrevemente !== 0) {
                setKey("disponivel-brevemente");
              } else {
                if (allListaMotas.length !== 0) {
                  setKey("motas");
                } else {
                  if (allListaBarcos.length !== 0) {
                    setKey("barcos");
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    viatura,
    allListaCarros,
    ArrayComerciais,
    ArrayClassicos,
    allListaMotas,
    allListaBarcos,
    ArrayEncomenda,
    ArrayBrevemente,
    allListaMotasNovas,
  ]);



  return (
    <>
      <div className={` ${!avancada ? "d-none" : "d-block"}`}>
        {isMobile && (
          <Col xs={12}>
            <div
              onClick={() => {
                setAvancada(false);
              }}
              style={{ marginTop: 0 }}
              className={`${css.textoLimpar} textoLimpar `}
            >
              <Seta style={{ marginBottom: 0, marginRight: "5px" }} /> pesquisa
              Rapida
            </div>
          </Col>
        )}

        <Tabs
          defaultActiveKey="automoveis"
          className={`mb-3 ${css.carroucel}`}
          activeKey={key}
          onSelect={(k) => setKey(k)}
          mountOnEnter={true}
        >
          {listPesquisaAvancado(
            ArrayEncomenda,
            ArrayBrevemente,
            allListaCarros,
            ArrayComerciais,
            ArrayClassicos,
            allListaMotas,
            allListaMotasNovas,
            allListaBarcos,
            infoJson
          ).map((item, index) => {
            return (
              item.exite && (
                <Tab
                  key={index}
                  eventKey={item.eventKey}
                  title={
                    <>
                      <div
                        onClick={() =>
                          navigate(item.navigate, {
                            replace: true,
                            state: { pesquisaAvancada: true },
                          })
                        }
                        className={`${css.tab} pesquisa-tab`}
                      >
                        {item.svg}
                        <div>{item.label}</div>
                        <div></div>
                      </div>
                    </>
                  }
                >
                  <TabContent>
                    <FormPesquisaAvancada
                      ArrayCarros={item.array}
                      setFilteredCarros={setFilteredCarros}
                      url={viatura ? viatura : "/"}
                      tipo={item.tipo}
                      infoJson={infoJson}
                      tipoViatura={item.tipoViatura}
                      allLista={allLista}
                    />
                  </TabContent>
                </Tab>
              )
            );
          })}

          {!isMobile && (
            <Tab
              tabClassName="ola"
              title={
                <>
                  <div
                    onClick={() => {
                      setAvancada(false);
                    }}
                    className={`pesquisa-tab ${css.tab} ${
                      !avancada ? "d-none" : "d-flex"
                    }`}
                    style={{ flexDirection: "initial" }}
                  >
                    <Seta
                      style={{
                        marginBottom: 0,
                        marginRight: "5px",
                        height: "25px",
                      }}
                    />
                    Pesquisa Rápida
                  </div>
                </>
              }
            ></Tab>
          )}
        </Tabs>
      </div>
    </>
  );
}
