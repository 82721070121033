import React from "react";

import { ReactComponent as Comercial } from "../../../../../../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../../../../../../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../../../../../../assets/icons/tipo/motos.svg";
import { ReactComponent as Classico } from "../../../../../../assets/icons/tipo/class.svg";
import { ReactComponent as Barcos } from "../../../../../../assets/icons/tipo/tipo_barco.svg";
import { ReactComponent as SvgRetomas } from "../../../../../../assets/icons/servicos/serv_retomas.svg";
import { ReactComponent as SvgDetalhes } from "../../../../../../assets/icons/servicos/serv_detalhe.svg";

export const listPesquisaAvancado = (
  ArrayEncomenda,
  ArrayBrevemente,
  allListaCarros,
  ArrayComerciais,
  ArrayClassicos,
  allListaMotas,
  allListaMotasNovas,
  allListaBarcos,
  infoJson
) => {
  return [
    {
      exite: ArrayBrevemente.length !== 0 ? true : false,
      eventKey: "disponivel-brevemente",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/disponivel-brevemente/#listagem_viaturas"
            : "/viaturas/disponivel-brevemente"
          : "/#listagem_viaturas"
      }`,
      svg: <SvgDetalhes />,
      label: "Disponíveis Brevemente",
      array: ArrayBrevemente,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "disponivel-brevemente"
          : "viaturas/disponivel-brevemente"
        : "disponivel-brevemente",
      tipoViatura: 1,
    },
    {
      exite: ArrayEncomenda.length !== 0 ? true : false,
      eventKey: "por-encomenda",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/por-encomenda/#listagem_viaturas"
            : "/viaturas/por-encomenda"
          : "/#listagem_viaturas"
      }`,

      svg: <SvgRetomas />,
      label: "Por Encomenda",
      array: ArrayEncomenda,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "por-encomenda"
          : "viaturas/por-encomenda"
        : "por-encomenda",
      tipoViatura: 1,
    },
    {
      exite: allListaCarros.length !== 0 ? true : false,
      eventKey: "automoveis",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/automoveis/#listagem_viaturas"
            : "/viaturas/automoveis"
          : "/#listagem_viaturas"
      }`,

      svg: <Berlina />,
      label: "Automóveis",
      array: allListaCarros,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "automoveis"
          : "viaturas/automoveis"
        : "automoveis",
      tipoViatura: 1,
    },
    {
      exite: ArrayComerciais.length !== 0 ? true : false,
      eventKey: "comerciais",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/comerciais/#listagem_viaturas"
            : "/viaturas/comerciais"
          : "/#listagem_viaturas"
      }`,
      svg: <Comercial />,
      label: "Comerciais",
      array: ArrayComerciais,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "comerciais"
          : "viaturas/comerciais"
        : "carros/comerciais/",

      tipoViatura: 1,
    },
    {
      exite: ArrayClassicos.length !== 0 ? true : false,
      eventKey: "classicos",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/classicos/#listagem_viaturas"
            : "/viaturas/classicos"
          : "/#listagem_viaturas"
      }`,
      svg: <Classico />,
      label: "Clássicos",
      array: ArrayClassicos,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "classicos"
          : "viaturas/classicos"
        : "carros/classicos/",

      tipoViatura: 1,
    },
    {
      exite: allListaMotas.length !== 0 ? true : false,
      eventKey: "motas-usadas",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/motas/#listagem_viaturas"
            : "/viaturas/motas-usadas"
          : "/motas/usadas/"
      }`,
      svg: <Motos />,
      label: "Motas Usadas",
      array: allListaMotas,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "motas-usadas"
          : "viaturas/motas-usadas"
        : "motas/usadas/",

      tipoViatura: 2,
    },
    {
      exite: allListaMotasNovas.length !== 0 ? true : false,
      eventKey: "motas-novas",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/motas/#listagem_viaturas"
            : "/viaturas/motas-novas"
          : "/motas/novas/"
      }`,
      svg: <Motos />,
      label: "Motas Novas",
      array: allListaMotasNovas,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "motas"
          : "viaturas/motas-novas"
        : "motas/novas/",

      tipoViatura: 2,
    },
    {
      exite: allListaBarcos.length !== 0 ? true : false,
      eventKey: "barcos",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/barcos/#listagem_viaturas"
            : "/viaturas/barcos"
          : "/barcos/usadas/"
      }`,
      svg: <Barcos stroke="none" />,
      label: "Barcos",
      array: allListaBarcos,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "barcos"
          : "viaturas/barcos"
        : "barcos/usados/",

      tipoViatura: 3,
    },
  ];
};
