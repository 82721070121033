import React, { useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import css from "../header.module.css";

export default function CustumNavDropdown({
  children,
  to,
  setactive,
  noactive,
}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  useEffect(() => {
    if (match) {
      setactive(true);
      noactive(false);
    }
  }, [match, setactive, noactive]);

  return (
    <NavDropdown.Item
      className={` ${match ? css.active : ""} ${match ? "active" : ""} ${
        css.boxMenuItem
      } boxMenuItemMenu`}
      as={Link}
      to={to}
    >
      {children}
    </NavDropdown.Item>
  );
}
