import React, { useEffect, useState } from "react";
import * as api from "../../../../api/apiCarros";
import Spacer from "../Spacer";
import BannerDestaque from "./BannerDestaque";

export default function DestaquesBanner({
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  
  allinfoText,
}) {
  const [ViaturasDestaques, setViaturasDestaques] = useState([]);
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });

  useEffect(() => {
    const fichaViatura = async () => {
      const viatura = await api.getAllDestaques(
        infoJson.Layout.Destaques.destaquesBanner.TipoDestaques,
        infoJson
      );

      setViaturasDestaques(viatura);
    };
    fichaViatura();
  }, [infoJson]);

  useEffect(() => {
    allinfoText.Destaques && setAlltexto(allinfoText.Destaques);
  }, [allinfoText]);

  return (
    <>
      {ViaturasDestaques.length !== 0 && (
        <div className={`p-0 position-relative container-fluid position`}>
          <Spacer height="85px" clasName="d-lg-none" />
          <>
            <BannerDestaque
              ViaturasDestaques={ViaturasDestaques}
              infoJson={infoJson}
              selectedItems={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
              alltexto={alltexto}
             
            />
          </>
        </div>
      )}
    </>
  );
}
